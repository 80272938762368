import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { introSectionContent as content } from '../content'
import { useLangContext } from '../../../context/lang.context'
import SectionImage from '../../../components/SectionImage'
import BannerGray from '../../../components/BannerGray'
import Video from '../../../components/Video'
import { colors, s } from '../../../style'
import TeamModalVideo from '../../../components/TeamModalVideo'

const IntroSection = () => {
  const { lang } = useLangContext()

  const query = useStaticQuery(graphql`
    query {
      image: file(absolutePath: { regex: "/images/career/intro.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 80
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      gptw: file(
        absolutePath: { regex: "/images/career/gptw.png/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
    }
  `)

  
  const gptwDesc = {
    title: { pl: 'Great Place to Work®', en: 'Great Place to Work®' },
    desc: {
      pl: `W 2023 otrzymaliśmy certyfikat <a href="https://www.greatplacetowork.pl/o-nas/nasza-misja-i-historia" target="_blank" rel="nofollow">Great Place to Work®</a>! <br/>Certyfikat ten został przyznany na podstawie badania wśród wszystkich naszych pracowników. Dziękujemy całej #EkipieGo za ich zaufanie i wkład w rozwój marki Cargonite.`,
      en: `In 2023 we received the <a href="https://www.greatplacetowork.pl/o-nas/nasza-misja-i-historia" target="_blank" rel="nofollow">Great Place to Work®</a> certificate! <br/>This certificate was awarded on the basis of a survey among all our employees. We would like to thank the whole #Go Team for their trust and contribution to the development of the Cargonite brand.`
    },
    imgPosition: '0% 50%',
  }
  
  const image = getImage(query.image)
  const gptwImg = getImage(query.gptw)


  return (
    <>
    <SectionImage
      title={content.title[lang]}
      desc={content.desc[lang]}
      back={content.back[lang]}
      image={image}
      imagePosition='top right'
    />
    <section css={sOuter}>
      <div css={sInner}>
        <BannerGray
          title={gptwDesc.title[lang]}
          desc={gptwDesc.desc[lang]}
          image={gptwImg}
          // button={{ text: desc.button.text[lang], link: desc.button.link }}
          imgPosition={gptwDesc.imgPosition}
        />
      </div>
    </section>
    <section css={sSection}>
      <TeamModalVideo />
    </section>
    {/* <Video data={content.video_url[lang]}/> */}
    </>

  )
}

const sOuter = {
  maxWidth: 1280,
  width: '100%',
  margin: '50px auto 0',
  display: 'flex',
  flexDirection: 'column',
}

const sSection = {
  width: '100%',
  maxWidth: 1280,
  margin: '100px auto 50px',
  display: 'flex',
  flexDirection: 'column',
  padding: '0 50px',
  [s.sm_down]: { padding: '0 50px', margin: '80px auto 80px' },
  [s.xs]: {
    padding: '0 25px',
  },
}

const sInner = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  margin: '0 50px',
  gap: 50,
  [s.xs]: {
    margin: '0 25px',
    gap: 25,
  },
  [s.sm_only]: {
    gap: 25,
  },
  [s.lg]: {
    margin: '50px 50px 0',
  },
}

export default IntroSection
