import React from 'react'
import ReactPlayer from 'react-player/lazy'
import { useLangContext } from '../context/lang.context'
import { colors, s } from '../style'

const Video = ({ data }) => {
  const { lang } = useLangContext()
  const { url } = data
  return (
    <>
      <section css={sOuter}>
      <div css={sInner}>
      <div css={sPlayer}>
        <ReactPlayer
          autoPlay
          css={sReactPlayer}
          url={data}
          loop
          width='100%'
          height='100%'
          playsinline
          config={{
            youtube: {
              playerVars: {
                controls: 1,
                showinfo: 0,
                modestbranding: 1,
                rel: 0,
                frameborder: 0,
                // disablekb: 1,
              },
            },
          }}
        />
      </div>
      </div>
      </section>
    </>
  )
}



const sMore = {
  display: 'grid',
  // gridTemplateRows: 'calc(9fr/16)',
  [s.sm_down]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
  [s.md]: {
    gridColumnGap: '2rem',
    gridRowGap: '2rem',
    marginTop: '2rem',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [s.lg]: {
    marginTop: '4rem',
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
}

const sPlayer = {
  backgroundColor: colors.greyLighter,
  width: '100%',
  overflow: 'hidden',
  height: '0',
  paddingTop: '56.25%',
  borderRadius: '1.5rem',
  position: 'relative',
  transform: 'translateZ(0)',
  backdropVisibility: 'hidden',

  [s.sm_down]: {
    borderRadius: '1rem',
    marginBottom: '1rem',
    height: 'calc(9 * (100vw - 4rem) / 16)',
  },
}

const sReactPlayer = {
  position: 'absolute',
  top: '0',
  left: '0',
}

const sPlayerSmall = {
  borderRadius: '1rem',
  [s.md]: { height: '100%' },
  [s.lg]: {
    maxHeight: 180,
  },
}

const sOuter = {
  maxWidth: 1280,
  width: '100%',
  margin: '50px auto 0',
  display: 'flex',
  flexDirection: 'column',
}

const sInner = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  margin: '0 50px 100px',
  gap: 50,
  [s.xs]: {
    margin: '0 25px 50px',
    gap: 25,
  },
  [s.sm_only]: {
    gap: 25,
  },
  [s.lg]: {
    margin: '50px 50px 100px',
  },
}

export default Video
