export const introSectionContent = {
  title: {
    pl: 'Kariera - Spedytor Międzynarodowy',
    en: 'Career',
  },
  back: {
    pl: 'CARGONITE',
    en: 'CARGONITE',
  },
  desc: {
    pl: 'Transport Spedycja Logistyka. Dołącz do zespołu CARGONITE i zmień swoją przyszłość. Poszukujemy spedytorów międzynarodowych.',
    en: 'Transport, Forwarding, Logistics. Join the CARGONITE team and change your future. We are looking for international forwarders.',
  },
  video_url: {
    pl: 'https://www.youtube.com/watch?v=LfF8YV3n1R4',
    en: 'https://www.youtube.com/watch?v=LfF8YV3n1R4',
  }
}

export const gallerySectionContent = {
  title: {
    pl: 'CARGONITE Moments',
    en: 'CARGONITE Moments',
  },
  back: {
    pl: 'CARGONITE',
    en: 'CARGONITE',
  },
  desc: {
    pl: 'Poczuj się jednym z nas! Uchwyciliśmy kilka momentów z życia zespołu.',
    en: 'Feel like being one of us! We have capture a few moments from a life of the team.',
  },
}

export const featuresSectionContent = {
  title: {
    pl: 'Dołącz do teamu CARGONITE!',
    en: 'Join the CARGONITE team!',
  },
  back: {
    pl: 'KARIERA',
    en: 'CAREER',
  },
  desc: {
    pl: 'Przekonaj się, dlaczego warto być częścią naszego zespołu.',
    en: 'See for yourself why it is worth being a part of our team.',
  },
  features: [
    {
      title: {
        pl: 'Premie',
        en: 'Bonuses',
      },
      desc: {
        pl: 'W CARGONITE dbamy o zadowolenie naszego zespołu. Stworzyliśmy korzystny system premiowy dla naszych pracowników.',
        en: 'At CARGONITE, we care about the satisfaction of our team. We have created a favorable bonus system for our employees.',
      },
    },
    {
      title: { pl: 'Integracja', en: 'Integration' },
      desc: {
        pl: 'Stawiamy na zgrany zespół i integrację. Zostając członkiem naszego zespołu, możesz liczyć na świetną atmosferę, wyjścia firmowe i imprezy integracyjne.',
        en: 'We focus on a well-coordinated team and integration. By becoming a member of our team, you can count on a great atmosphere, company outings and integration events.',
      },
    },
    {
      title: { pl: 'Szkolenia', en: 'Training' },
      desc: {
        pl: 'Szkolimy się! Stawiamy na doświadczenie i edukację. Każdy nowy pracownik otrzymuje szkolenie wstępne oraz pakiet szkoleniowy, podnoszący kwalifikacje w ciągu roku. ',
        en: `Let's learn! We focus on experience and education. Each new employee receives an initial training and a courses package improving qualifications throughout the year.`,
      },
    },
    {
      title: { pl: 'Zespół', en: 'Team' },
      desc: {
        pl: 'Stawiamy na bardzo dobrą atmosferę w pracy i podejście fair play. Zobacz zdjęcia z naszych integracji i przekonaj się, że warto być częścią CARGONITE! P.S. My naprawdę mamy fajny zespół 😎',
        en: 'We focus on a very good atmosphere at work and a fair play approach. Take a look at photos from our integrations and see for yourself that it is worth being part of CARGONITE! BTW we cool for real 😎',
      },
    },
    {
      title: { pl: 'Wyposażenie', en: 'Equipment' },
      desc: {
        pl: 'Pracując w CARGONITE otrzymujesz pełne wyposażenie i nowoczesny sprzęt do pracy na swoim stanowisku.',
        en: 'Work for CARGONITE and get full equipment and modern devices.',
      },
    },
    {
      title: { pl: 'Benefity', en: 'Benefits' },
      desc: {
        pl: 'Chcemy, by nasz zespół miał to, co najlepsze. Każdy z zespołu otrzymuje dopłatę do karty MultiSport oraz pakietu prywatnej opieki zdrowotnej.',
        en: 'We want our team to have the best. Each team member receives a supplement to the MultiSport card and private healthcare package.',
      },
    },
  ],
}
