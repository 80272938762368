export const seo = {
  title: {
    pl: 'Kariera TSL | Praca Spedytor | CARGONITE',
    en: 'Career in TSL | Forwarder Jobs',
  },
  description: {
    pl: 'Transport Spedycja Logistyka. Dołącz do zespołu CARGONITE i zmień swoją przyszłość. Poszukujemy spedytorów międzynarodowych.',
    en: 'Transport, Forwarding, Logistics. Join the CARGONITE team and change your future. We are looking for international forwarders.',
  },
}
