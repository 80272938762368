import React from 'react'
import { featuresSectionContent as content } from '../content'
import FeaturesComponent, { sOuter } from '../../../components/Features'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { alpha, colors, s } from '../../../style'
import Triangle from '../../../assets/Triangle'

const Features = () => {
  const query = useStaticQuery(graphql`
    query {
      team: file(absolutePath: { regex: "/images/career/team.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            layout: FULL_WIDTH
            quality: 100
            formats: [JPG]
            placeholder: BLURRED
          )
        }
      }
      image1: file(
        absolutePath: { regex: "/images/career/features/money.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            formats: [JPG]
            placeholder: BLURRED
          )
        }
      }
      image2: file(
        absolutePath: { regex: "/images/career/features/party.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            formats: [JPG]
            placeholder: BLURRED
          )
        }
      }
      image3: file(
        absolutePath: { regex: "/images/career/features/training.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            formats: [JPG]
            placeholder: BLURRED
          )
        }
      }
      image4: file(
        absolutePath: { regex: "/images/career/features/team.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            formats: [JPG]
            placeholder: BLURRED
          )
        }
      }
      image5: file(
        absolutePath: { regex: "/images/career/features/equipment.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            formats: [JPG]
            placeholder: BLURRED
          )
        }
      }
      image6: file(
        absolutePath: { regex: "/images/career/features/benefits.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            formats: [JPG]
            placeholder: BLURRED
          )
        }
      }
    }
  `)

  return (
    <FeaturesComponent
      title={content.title}
      desc={content.desc}
      back={content.back}
      features={content.features.map((feature, id) => ({
        ...feature,
        image: getImage(query[`image${id + 1}`].childImageSharp),
      }))}>
      <div css={[sOuter]}>
        <div css={sOuterImage}>
          <GatsbyImage
            image={getImage(query.team.childImageSharp)}
            alt='TEAM CARGONITE'
            css={{
              height: 'auto',
              width: '100%'
              // [s.xs]: {
              //   width: 'calc(100vw - 50px)',
              //   height: 'calc(calc(100vw - 50px) * 0.6653)',
              //   maxHeight: 'calc(calc(100vw - 50px) * 0.6653)',
              //   maxWidth: 'calc(100vw - 50px)',
              // },
              // [s.sm]: {
              //   width: 'calc(100% - 100px)',
              //   height: 'calc(calc(100vw - 100px) * 0.6653)',
              // },
              // maxWidth: 1580,
              // maxHeight: 1580 * 0.6653,
              // [s.lg]: {
              //   maxHeight: 1580 * 0.6653,
              // },
            }}
          />
          <div css={sGlow} />
          <Triangle
            extraCss={{
              position: 'absolute',
              top: 0,
              left: 0,
              color: colors.main,
              zIndex: 10,

              [s.lg]: {
                width: 60,
                height: 60,
              },
            }}
          />
        </div>
      </div>
    </FeaturesComponent>
  )
}

const sOuterImage = {
  position: 'relative',
  zIndex: 10,
  top: 0,
  left: 0,
  width: '100%'
}

const sGlow = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 10,
  boxShadow: `
inset 0px 0px 25px 0px ${colors.main.concat(alpha[100])},
inset 0px 0px 100px 0px ${colors.main.concat(alpha[100])},
0px 0px 25px 0px ${colors.main.concat(alpha[100])}, 
0px 0px 100px 0px ${colors.main.concat(alpha[100])}, 
0px 0px 0px 2px ${colors.main.concat(alpha[100])},
0px 0px 0px 2px ${colors.main.concat(alpha[100])}
 `,
  [s.xs]: {
    boxShadow: `
    inset 0px 0px 25px 0px ${colors.main.concat(alpha[100])},
    0px 0px 25px 0px ${colors.main.concat(alpha[100])}, 
    0px 0px 0px 2px ${colors.main.concat(alpha[100])}
     `,
  },
}

export default Features
