import React from 'react'
import Career from '../page/Career/Career'
import Layout from '../components/Layout'

const CareerPage = () => (
  <Layout>
    <Career />
  </Layout>
)

export default CareerPage
