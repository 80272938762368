import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useLangContext } from '../../../context/lang.context'
import { s } from '../../../style'
import Thumbnail from '../../../components/Thumbnail'
import { getImage } from 'gatsby-plugin-image'
import { gInput, gSelect } from '../../../style/config/globals'

const JobsAll = () => {
  const { lang } = useLangContext()
  const [searchPhrase, setSearchPhrase] = useState('')
  const [searchCategory, setSearchCategory] = useState('')

  const query = useStaticQuery(graphql`
    query {
      jobs: allContentfulJob(sort: { fields: createdAt, order: DESC }) {
        edges {
          node {
            title
            desc
            slug
            position
            image {
              title
              gatsbyImageData(
                width: 600
                layout: FULL_WIDTH
                quality: 100
                placeholder: BLURRED
                formats: [JPG]
              )
            }
            lang {
              slug
            }
          }
        }
      }
    }
  `)

  const jobs = query.jobs.edges
    .filter(({ node }) => node.lang.slug === lang)
    .map(({ node }) => node)

  return (
    <section css={sOuter}>
      <div css={sInner}>
        {jobs.map((job, id) => (
          <Thumbnail
            key={id}
            title={job.position}
            subtitle={lang === 'pl' ? 'Praca jako' : 'Job offeer'}
            desc={job.desc}
            image={getImage(job.image)}
            to={`/career/${job.slug}`}
            isBlog
          />
        ))}
      </div>
    </section>
  )
}

const sOuter = {
  maxWidth: 1280,
  width: '100%',
  margin: '50px auto 0',
  display: 'flex',
  flexDirection: 'column',
}

const sInner = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  margin: '0 50px 100px',
  gap: 50,
  [s.xs]: {
    margin: '0 25px 50px',
    gap: 25,
  },
  [s.sm_only]: {
    gap: 25,
  },
  [s.lg]: {
    margin: '50px 50px 100px',
  },
}

export default JobsAll
