import React from 'react'
import Seo from '../../components/Seo'
import { seo } from './seo'
import { useLangContext } from '../../context/lang.context'
import IntroSection from './IntroSection/IntroSection'
import FormCareer from './FormCareer/FormCareer'
import JobsAll from './JobsAll/JobsAll'
import Features from './Features/Features'
import Gallery from './Gallery/Gallery'

const Collab = () => {
  const { lang } = useLangContext()

  return (
    <>
      <Seo
        title={seo.title[lang]}
        description={seo.description[lang]}
        lang={lang}
      />
      <IntroSection />
      <JobsAll />
      <Features />
      {/* <Gallery /> */}
      <FormCareer />
    </>
  )
}

export default Collab
